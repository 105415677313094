<template>
  <div id="saleInvoicesPage">
    <router-view/>
  </div>
</template>

<script>
export default {
  name: 'index',
  created () {
    this.$store.dispatch('auth/setAccessToken')
  }
}
</script>

<style scoped>

</style>
